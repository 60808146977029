<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6" >
                <h4>
                  <router-link :to="{name:'students'}">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>
                  Add Staff
                </h4>
              </div>
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-12 form-group">
                <label for class="float-left">Title</label>
                <select class="form-control" name id v-model="user.title">
                  <option value="null"> Title</option>
                  <option value="mr">Mr</option>
                  <option value="mrs">Mrs</option>
                  <option value="ms">Ms</option>
                </select>
                <span class="text-danger" v-if="$v.user.title.$error">Title is required</span>
              </div>
              <div class="col-md- col-lg-3 col-sm-12 form-group">
                <label for class="float-left">First name</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="user.first_name"
                    placeholder="First Name"
                />
                <span class="text-danger" v-if="$v.user.first_name.$error">First Name is required</span>
              </div>
              <div class="col-md- col-lg-3 col-sm-12 form-group">
                <label for class="float-left">Middle name</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="user.middle_name"
                    placeholder="Middle Name"
                />
              </div>
              <div class="col-md- col-lg-3 col-sm-12 form-group">
                <label for class="float-left">Last name</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="user.last_name"
                    placeholder="Last Name"
                />
                <span class="text-danger" v-if="$v.user.last_name.$error">Last Name is required</span>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-12 form-group">
                <label for class="float-left">Email</label>
                <input type="email" class="form-control" v-model="user.email" placeholder="Email"
                       :readonly="this.user.id"/>
                <span class="text-danger" v-if="!this.id && $v.user.email.$error">Email should be valid one</span>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-12 form-group">
                <label for class="float-left">Phone</label>
                <input type="text" class="form-control" v-model="user.phone" placeholder="Phone"
                       :readonly="this.user.id"/>
                <span class="text-danger"
                      v-if="!this.id && $v.user.phone.$error">Phone is required</span>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-12 form-group">
                <label for class="float-left">Mobile</label>
                <input type="text" class="form-control" v-model="user.mobile" placeholder="Phone"
                       :readonly="this.user.id"/>
              </div>

              <div class="col-md-3 col-lg-3 col-sm-12 form-group">
                <label for class="float-left">Date of birth</label>
                <div class="col-12 p-0 form-group">
                  <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <input
                          dense
                          outlined
                          v-model="user.dob"
                          v-bind="attrs"
                          v-on="on"
                          class="form-control"
                      />
                    </template>
                    <v-date-picker
                        v-model="user.dob"
                        @input="menu2 = false; convertAdToBs()"
                        header-color="primary"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <span class="text-danger" v-if="$v.user.type.$error">Role is required</span>
              </div>
              <!--                            <div class="col-md-4 col-lg-4 col-sm-12 form-group">-->
              <!--                                <label for class="float-left">Batch</label>-->
              <!--                                <v-select-->
              <!--                                        outlined-->
              <!--                                        dense-->
              <!--                                        class="form-control"-->
              <!--                                        v-model="studentSetting.academic_year_id"-->
              <!--                                        :items="academicYears"-->
              <!--                                        label="Select Batch"-->
              <!--                                        item-value="id"-->
              <!--                                        item-text="year"-->
              <!--                                ></v-select>-->
              <!--                                <span class="text-danger" v-if="$v.user.type.$error">Role is required</span>-->
              <!--                            </div>-->
              <!--                            <div class="col-md-4 col-lg-4 col-sm-12 form-group">-->
              <!--                                <label for class="float-left">Level</label>-->
              <!--                                <v-select-->
              <!--                                        outlined-->
              <!--                                        dense-->
              <!--                                        class="form-control"-->
              <!--                                        v-model="studentSetting.level_id"-->
              <!--                                        :items="levels"-->
              <!--                                        label="Level"-->
              <!--                                        item-value="id"-->
              <!--                                        item-text="title"-->
              <!--                                        @change="getPrograms"-->
              <!--                                ></v-select>-->
              <!--                                <span class="text-danger" v-if="$v.user.type.$error">Role is required</span>-->
              <!--                            </div>-->
              <!--                            <div class="col-md-4 col-lg-4 col-sm-12 form-group">-->
              <!--                                <label for class="float-left">Programs</label>-->
              <!--                                <v-select-->
              <!--                                        outlined-->
              <!--                                        dense-->
              <!--                                        class="form-control"-->
              <!--                                        v-model="studentSetting.program_id"-->
              <!--                                        :items="programs"-->
              <!--                                        label="Programs"-->
              <!--                                        item-value="id"-->
              <!--                                        item-text="title"-->
              <!--                                        @change="getGrades"-->
              <!--                                ></v-select>-->
              <!--                                <span class="text-danger" v-if="$v.user.type.$error">Program is required</span>-->
              <!--                            </div>-->
              <!--                            <div class="col-md-4 col-lg-4 col-sm-12 form-group">-->
              <!--                                <label for class="float-left">Semester/Year</label>-->
              <!--                                <v-select-->
              <!--                                        outlined-->
              <!--                                        dense-->
              <!--                                        class="form-control"-->
              <!--                                        v-model="studentSetting.grade_id"-->
              <!--                                        :items="gradesLevels"-->
              <!--                                        label="Semester/Year"-->
              <!--                                        item-value="id"-->
              <!--                                        item-text="title"-->
              <!--                                        @change="getAcademicClasses"-->
              <!--                                ></v-select>-->
              <!--                                <span class="text-danger" v-if="$v.user.type.$error">Role is required</span>-->
              <!--                            </div>-->
              <!--                            <div class="col-md-4 col-lg-4 col-sm-12 form-group">-->
              <!--                                <label for class="float-left">Classroom</label>-->
              <!--                                <v-select-->
              <!--                                        outlined-->
              <!--                                        dense-->
              <!--                                        class="form-control"-->
              <!--                                        v-model="studentSetting.class_id"-->
              <!--                                        :items="academic_classes"-->
              <!--                                        label="Class Name"-->
              <!--                                        item-value="id"-->
              <!--                                        item-text="title"-->
              <!--                                ></v-select>-->
              <!--                                <span class="text-danger" v-if="$v.user.type.$error">Role is required</span>-->
              <!--                            </div>-->

              <div class="col-12 form-group">
                <label for class="float-left">Description</label>
                <textarea
                    name
                    id
                    class="form-control"
                    v-model="user.description"
                    placeholder="Description"
                    cols="30"
                    rows="10"
                ></textarea>
              </div>

              <!-- <span class="text-danger" v-if="$v.user.is_active.$error">Title is required</span> -->
              <div class="col-md-3 col-lg-3 col-sm-6 form-group">
                <v-switch
                    v-model="user.is_email_verified"
                    label="Verifed Email"
                >
                </v-switch>


                <!-- <span class="text-danger" v-if="$v.user.is_active.$error">Title is required</span> -->
              </div>
              <div class="col-md-3 col-lg-3 col-sm-6 form-group">

                <v-switch
                    v-model="user.is_active"
                    label="Status"
                >
                </v-switch>
              </div>
            </div>


            <div class="col-12">
              <img
                  v-if="user.image_path"
                  :src="user.image_path['thumb']"
                  alt
                  style="height:75px"
                  class="img-thumbnail"
              />
            </div>

            <div class="col-12 mt-5">
              <div class="float-right">
                <v-btn
                    class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                    depressed
                    @click="redirectTo"
                >Cancel
                </v-btn>
                <v-btn v-if="checkIsAccessible('user', 'create')"
                    class="text-primary btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                    depressed
                    @click="createOrUpdate"
                    :loading="isBusy"
                >Save
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import JobPositionService from "@/core/services/job-position/JobPositionService";
import ProgramService from "@/core/services/level/program/ProgramService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";

const userService = new UserService();
const positionService = new JobPositionService();
const programService = new ProgramService();
const gradeLevelService = new GradeLevelService();
const academicClassService = new AcademicClassService();
const academicYearService = new AcademicYearService();
const levelService = new LevelService()
const enrollmentService = new UserEnrollmentService();

export default {
  name: "slider-new",
  validations() {
    return {
      user: this.rules,
    };
  },
  data() {
    return {
      isBusy: false,
      sizeExceed: false,
      job_types: ["admin", "student", "coordinator", "principle"],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      academic_classes: [],
      levels: [],
      levelId: null,
      menu2: false,

      studentSetting: {
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        class_id: null,
      },
      user: {
        title: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        phone: null,
        mobile: null,
        description: null,
        type: 'staff',
        is_email_verified: true,
        is_active: true,
        dob: null
      }
    };
  },

  mounted() {
    this.id ? this.getUser(this.id) : "";
    // this.getLevels()
    // this.getAcademicYears()
    // this.getGrades()
    // this.getAcademicClasses()

  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },

    rules() {
      let rule = {
        title: {required},
        first_name: {required},
        last_name: {required},
        type: {required}
      };
      if (!this.id) {
        rule.email = {required};
        rule.phone = {required};
      }
      return rule;
    }
  },
  methods: {
    numberFormat(amount) {
      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "INR"
      });
    },
    getUser(id) {
      userService.show(id).then(response => {
        this.user = response.data.user;
        this.user.is_active ? (this.user.is_active = true) : false;
        this.user.is_email_verified
            ? (this.user.is_email_verified = true)
            : false;
        this.studentSetting = this.user.setting
        this.edit = true;
        this.getLevels()
      });
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;
        if (this.studentSetting.id && this.studentSetting.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.studentSetting.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academicYears = response.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.studentSetting.program_id).then(response => {
        this.gradesLevels = response.data;

      });
    },
    getAcademicClasses() {
      academicClassService.getByProgramLevel(this.studentSetting.academic_year_id, this.studentSetting.program_id, this.studentSetting.grade_id).then(response => {
        this.academic_classes = response.data;
      });
    },
    save(date) {
      this.$refs.menu2.save(date);
    },
    convertAdToBs() {
      let data = {
        dob: this.user.dob
      };
      enrollmentService
          .convertAdToBs(data)
          .then(response => {
            this.user.dob_bs = response.data.dob;
          })
          .catch(error => {
            //console.log(error);
          });
    },
    redirectTo() {
      this.$router.push({name: "staff"});
    },
    saveStudent() {
      this.isBusy = true;
      userService
          .createByAdmin(this.user)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Student record added");
            this.$router.push({name: "staff"});
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Error updating record. Please try again later.");
          });
    },

    createOrUpdate() {
      this.$v.user.$touch();
      if (this.$v.user.$error) {
        setTimeout(() => {
          this.$v.user.$reset();
        }, 3000);
      } else {
        // this.user.setting = this.studentSetting
        if (this.edit) {
          this.updateStudent();
        } else {
          this.saveStudent();
        }
      }
    },
    updateStudent() {
      this.isBusy = true;
      delete this.user.email;
      delete this.user.phone;
      userService
          .updateUser(this.user.id, this.user)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Student record updated");
            this.$router.push({name: "staff"});
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Error updating record. Please try again later.");
          });
    }
  }
};
</script>
